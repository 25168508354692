import React from "react"

import SEO from "../components/seo"

import logo from "../images/logo.png"
import "../components/404/not-found.css"

const NotFoundPage = () => (
  <div className="not-found">
    <SEO title="404: Not Found - Credit 101" />
    <div>
      <a href="/" className="block mx-auto pt-8">
        <img src={logo} alt="Logo" id="logo" className="h-12 m-auto text-center"/>
        </a>
    </div>
    <div className="vc-child block">
      <h1 className="text-center text-4xl md:text-6xl font-bold">Oops</h1>
      <p className="text-center text-lg md:text-2xl font-medium mb-6 md:mb-8">The page you are looking for is not found.</p>
      <div className="text-center"><a className="home-btn text-base md:text-xl px-4 py-3 rounded font-bold" href="/">Home Page</a></div>
    </div>
  </div>
)

export default NotFoundPage
